<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ venue.title }}</h1></template
      >
      <template v-slot:item-slot>
        <router-link class="breadcrumb-item" :to="{ name: 'Venue' }"
          >Venue</router-link
        >
        <div class="breadcrumb-item">{{ venue.title }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">{{ venue.title }}</h2>
      <p class="section-lead">
        Examples and usage guidelines for form control styles, layout options,
        and custom components for creating a wide variety of forms.
      </p>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <h4>Detail</h4>
                <router-link
                  :to="{ name: 'VenueEdit', params: { id: venue.id } }"
                  class="btn btn-warning mr-1"
                  >Edit</router-link
                >
              </div>
            </div>
            <div class="card-body">
              <h5>{{ venue.title }}</h5>
              <div class="row">
                <div class="col-md-6">
                  <p>{{ venue.address }}</p>
                </div>
                <div class="col-md-6">
                  <p class="text-right">
                    {{ venue.open_at }} - {{ venue.closed_at }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <img :src="venue.image" height="100px" alt="" />
                </div>
                <div class="col-md-6">
                  <img :src="venue.image_table" height="100px" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      venue: [],
      token: localStorage.getItem("token"),
    };
  },
  created() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "venue/" + id, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => (this.venue = res.data.data))
      .catch((err) => console.log(err));
  },
};
</script>
